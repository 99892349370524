export default {
    title: 'Store Fulfilment Productivity',
    location: '/business/fuo/store-fulfilment-productivity',
    tabs: [
        {
            name: 'By Country',
            link: `/business/fuo/store-fulfilment-productivity`
        },
        {
            name: 'Trend',
            link: `/business/fuo/store-fulfilment-productivity/:market`
        }
    ]
}
